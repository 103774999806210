<template>
  <v-row class="row--35" align="center">
    <v-col lg="5" md="5" cols="12">
      <div class="thumbnail">
        <!-- <slot name="thum-img"></slot> -->
        <img
            src="../../assets/images/ellavate/HS_Fly.jpeg"
            alt="Slider Images"
          />
        <span>© Hochschule Trier</span>
      </div>
    </v-col>
    <v-col lg="7" md="7" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <span class="subtitle">Lerne uns kennen</span>
          <h2 class="heading-title">Über uns</h2>
          <p class="description mt_dec--20">
            Willkommen bei unserem Projekt zur Verbesserung des Hochschullebens an der Hochschule Trier!
            Wir sind Jenny, Sophie, René, Jahn, Maik, Luis und Jonathan und studieren im Master Business Management mit der Vertiefungsrichtung Entrepreneurship. In unserer Veranstaltung "Opportunity Recognition" haben wir festgestellt, dass es zahlreiche Herausforderungen gibt, denen sich Studierende tagtäglich gegenübersehen. Sei es der Mangel an Lernplätzen in der Bibliothek, die ewig lange Schlange mittags vor der Mensa oder andere Schwierigkeiten, die Studierende beeinträchtigen können.
            <br />
            <br />
            Um diesen Herausforderungen zu begegnen, haben wir beschlossen, aktiv zu werden und eine Plattform zu schaffen, auf der Studierende ihre Anliegen äußern können.
          </p>
        </div>
        <!-- End .section-title -->
        <div class="tab-wrapper mt--30">
          <v-row>
            <TabTwo />
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import TabTwo from "../../components/ellavate/About_Tab";
  export default {
    components: { TabTwo },
    data() {
      return {};
    },
  };
</script>
