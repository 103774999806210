<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="text-left section-title mb--50">
        <span class="subtitle">Helfe uns neue Lösungsansätze zu finden</span>
        <h2 class="heading-title">Reiche deine Probleme ein</h2>
        <div class="im_address mt--5">
          <span>Hier kannst du deine Probleme bis zum 02.06.2024 einreichen!</span>
        </div>
      </div>
      <div class="form-wrapper">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <!-- <ValidationProvider
              name="name"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.name"
                  placeholder="Name"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider> -->

            <!-- <ValidationProvider
              name="email"
              v-slot="{ errors }"
              >
              <label>
                <input
                  type="text"
                  v-model="formData.email"
                  placeholder="E-Mail"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider> -->

            <!-- <ValidationProvider
              name="subject"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.subject"
                  placeholder="Titel deines Problems"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider> -->

              <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
              >
                <label>
                  <textarea
                    v-model="formData.message"
                    placeholder="Dein Problem (Maximal 2048 Zeichen)"
                  ></textarea>
                  <span 
                    class="inpur-error"
                  >
                    {{ errors[0] }}
                  </span>
                </label>
              </ValidationProvider>

            <div class="im_address mt--5">
              <span><b>{{ status }}</b></span>
            </div>

            <button class="btn-default" type="submit" value="submit">
              Absenden
            </button>

            
            
          </form>
        </ValidationObserver>
      </div>
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
      <div class="thumbnail mb_md--40 mb_sm--40">
        <!-- <slot name="contact-img"></slot> -->
        <img
            src="../../assets/images/ellavate/HS_Ideen.jpg"
            alt="Slider Images"
          />
          <span>© Hochschule Trier</span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import axios from 'axios';
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        formData: {
          message: "",
        },
        status: ""
      };
    },
    methods: {
      async onSubmit() {

      if (!this.formData.message || this.formData.message === '') {
        return
      }

      try {
        let response = await axios.post(`https://api.ellavate.study/problem`, {
          message: this.formData.message
        })
        if (response.status == 200) {
          this.status = "Vielen Dank für deine Einreichung!";
          this.formData.message = "";
          setTimeout(() => {
            location.reload()
          }, 2000)
        }
      } catch (err) {
        this.status = "Etwas ging schief. Bitte versuche es nochmal oder kontaktiere uns";
      }
    },
    },
  };
</script>

<style lang="scss">
  .form-wrapper label input,
  .form-wrapper label textarea {
    margin-bottom: 0;
  }
  .form-wrapper label {
    margin-bottom: 20px;
  }
</style>
