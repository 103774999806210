<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          transition="fade-transition"
          v-for="item in tabContent"
          :key="item.id"
        >
          <v-card flat>
            <v-card-text>
              <div class="single-tab-content">
                <div>
                  <div class="section-title">
                    <h4 class="heading-title">{{ item.title }}</h4>
                    <h5 class="heading-title">{{ item.time }}</h5>
                    <p class="description mt_dec--35">
                      {{ item.content }}
                    </p>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>
<script>
  export default {
    data() {
      return {
        tab: null,
        items: [
          {
            id: 1,
            name: "Phase 1",
          },
          {
            id: 2,
            name: "Phase 2",
          },
          {
            id: 3,
            name: "Phase 3",
          }
        ],

        tabContent: [
        {
            id: 1,
            time: '14.05.2024 - 02.06.2024',
            title: "Einreichung von Problemen",
            content: "Über den Reiter „Problem Einreichen“ auf unserer Website, könnt ihr bis zum 02.06.2024 ganz einfach die Probleme benennen, die euch an der Hochschule Trier stören. Diese Probleme sollten für die Hochschule insgesamt gelten und für viele Studierende relevant sein, also keine individuellen Angelegenheiten betreffen."
          },
          {
            id: 2,
            time: '10.06.2024 - 30.06.2024',
            title: "Einreichung von Lösungsansätzen",
            content: "Studierende können Konzepte für die Lösung der ausgewählten Problemstellungen entwickeln und einreichen."
          },
          {
            id: 3,
            time: 'Ab 01.07.2024',
            title: "Bewertung der Lösungsansätze durch eine Jury",
            content: "Eine Jury bewertet die abgegebenden Konzepte und wählt die Gewinner aus. Weitere Informationen hierzu folgen demnächst."
          },
        ],
      };
    },
  };
</script>
